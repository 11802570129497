
import {Options, Vue} from 'vue-class-component'
import ComponentProfile from '@/components/Profile.vue'

@Options({
  components: {
    ComponentProfile
  },

  mounted () {
    
  }
})
export default class Client extends Vue {}
