<template>
  <div class="awards">
    <ComponentProfile/>
  </div>

  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
  <div class="VerticalSpacer_Middle"></div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import ComponentProfile from '@/components/Profile.vue'

@Options({
  components: {
    ComponentProfile
  },

  mounted () {
    
  }
})
export default class Client extends Vue {}
</script>